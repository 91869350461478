import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import ResiROICalcForm from 'components/Tools/ResiROICalculator';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';

const ResiROICalculatorPage = (props) => {
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs('Resi ROI Calculator');

  return (
    <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
      <Hero data={data.hero} />
      <ResiROICalcForm />
      {data.description && <DescriptionBlock data={data.description} />}
    </ToolboxLayout>
  );
};

export default ResiROICalculatorPage;

export const ResiROICalculatorPageQuery = graphql`
  query ResiROICalculatorPageQuery {
    contentfulTool(internalName: { eq: "Residential S&R ROI Calculator" }) {
      ...Tool
    }
  }
`;
