import React, { useState, useEffect, useContext } from 'react';
import { globalHistory } from '@reach/router';
import { Accordion, AccordionSummary, AccordionDetails, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import TextField from 'components/Core/TextField';
import { STIcon } from 'components/Brand/Icons/STIcon';
import useRoiCalcImages from 'hooks/useRoiCalcImages';
import SweetScrollContext from 'utils/SweetScrollContext';
import EmbedForm from '../EmbeddableForm';
import { ResiROIFormContent, ResiROIResultContent } from './static';
import { ROIFormContainer, ROIPackageContainer } from './styles';
import { FormContainer } from '../styles';

const ResiROIForm = ({ width, height, ...otherProps }) => {
  const roiCalcImages = useRoiCalcImages();
  const { scrollToAnchor } = useContext(SweetScrollContext);
  const icons = {};

  icons.person =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-person-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.eval =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-eval-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.wrench =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-wrench-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.money =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-money-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.document =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-document-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const [formValues, setFormValues] = useState({
    m: '',
    oh: '',
    th: '',
    c: '',
    tRc: '',
    cCr: '',
    rRj: '',
    rSj: '',
    j: '',
    q: '',
    tL: '',
    rtCr: '',
    stCr: '',
    mo: '',
    mCr: '',
    oEHr: '',
    rtEHr: '',
    oE: '',
  });

  const packageCost = {
    starter: 199,
    essentials: 249,
    works: 299,
  };

  const [isFirstLoad, setFirstLoad] = useState(false);
  const [selPackage, setSelPackage] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [activeKey, setActiveKey] = useState({
    pillar1: 0,
    pillar2: 0,
    pillar3: 0,
    pillar4: 0,
  });

  const [isValid, setValid] = useState(false);
  const [isResult, setResult] = useState(false);
  const [formResult, setFormResult] = useState({
    amazingCustomerExperience: 0,
    increaseSales: 0,
    lowerCostsAndSaveTime: 0,
    accountabilityAndControl: 0,
    monthlyStSubscription: 0,
    monthlyRevenueGrowth: 0,
    returnOnInvestment: 0,
    returnOnInvestmentPercent: 0,
  });

  const getUrlState = () => {
    return {
      stPackage: selPackage,
      stPackageValue: packageCost[selPackage],
      ...formValues,
    };
  };

  const getUrlQuery = () => {
    const urlState = getUrlState();
    return Object.keys(urlState).reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue}=${urlState[currentValue]}&`;
    }, '');
  };

  const getHistoryUrl = () => {
    return `${window.location.origin}${window.location.pathname}?${getUrlQuery()}`;
  };

  const pushHistory = () => history.pushState(getUrlState(), 'roi-calculator', getHistoryUrl());

  const prepareNumber = (value) => value.replace(',', '');

  const prepareResult = (value) =>
    Math.floor(value)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const percentToFloat = (value) => parseFloat(value / 100);

  const calculatePillar = (pillar, index) => {
    const calcFields = {
      pillar1: {
        starter: ['online_booking', 'automated_text_alerts', 'call_pop'],
        essentials: [
          'online_booking',
          'e_proposals',
          'automated_text_alerts',
          'call_pop',
          'tech_tracking',
          'two_way_chat',
        ],
        works: [
          'online_booking',
          'e_proposals',
          'automated_text_alerts',
          'call_pop',
          'tech_tracking',
          'customer_payment_portal',
          'two_way_chat',
        ],
      },
      pillar2: {
        starter: [
          'targeted_reporting',
          'follow_ups',
          'enhanced_presentation',
          'estimate_templates',
          'financing',
          'smart_tags',
          'gls',
          'smart_dispatch',
        ],
        essentials: [
          'targeted_reporting',
          'follow_ups',
          'enhanced_presentation',
          'estimate_templates',
          'financing',
          'smart_tags',
          'gls',
          'smart_dispatch',
        ],
        works: [
          'targeted_reporting',
          'follow_ups',
          'enhanced_presentation',
          'estimate_templates',
          'financing',
          'smart_tags',
          'gls',
          'smart_dispatch',
        ],
      },
      pillar3: {
        starter: [
          'marketing_tracking',
          'paperless_invoicing',
          'mobile_customer_history',
          'mobile_upcoming',
        ],
        essentials: [
          'marketing_tracking',
          'paperless_invoicing',
          'mobile_customer_history',
          'mobile_payments',
          'mobile_upcoming',
        ],
        works: [
          'marketing_tracking',
          'paperless_invoicing',
          'automated_payroll',
          'automated_memberships',
          'automated_reporting',
          'mobile_customer_history',
          'mobile_payments',
          'mobile_upcoming',
        ],
      },
      pillar4: {
        starter: [
          'real_time_notification',
          'material_tracking',
          'dashboard',
          'forms_gated_workflow',
        ],
        essentials: [
          'real_time_notification',
          'job_costing',
          'material_tracking',
          'dashboard',
          'forms_gated_workflow',
        ],
        works: [
          'customer_reporting',
          'inventory',
          'real_time_notification',
          'job_costing',
          'material_tracking',
          'dashboard',
          'forms_gated_workflow',
        ],
      },
    };

    return calcFields[index][selPackage].reduce(
      (accumulator, currentValue) => accumulator + Math.round(pillar[currentValue]),
      0,
    );
  };

  const calculate = () => {
    const { m, oh, th, rRj, rSj, j, q, rtCr, stCr, mo, mCr, oEHr, rtEHr, oE } = {
      ...formValues,
      cCr: percentToFloat(formValues.cCr),
      rtCr: percentToFloat(formValues.rtCr),
      stCr: percentToFloat(formValues.stCr),
      mCr: percentToFloat(formValues.mCr),
    };

    const pillar1 = {};
    const pillar2 = {};
    const pillar3 = {};
    const pillar4 = {};

    pillar1.online_booking = j * rRj * 0.1 + q * rSj * 0.05 + oh * (5 / 60);
    pillar1.e_proposals = j * rtCr * 1.02 * (rRj * 1.04) + q * stCr * 1.02 * (rSj * 1.04);
    pillar1.automated_text_alerts = j * oh * 0.8 + j * th * 0.8;
    pillar1.call_pop = j * 1.03 * rRj * 1.02 + m * 0.03;
    pillar1.tech_tracking = rRj * 1.02;
    pillar1.customer_payment_portal = oE * rtEHr * 4;
    pillar1.two_way_chat = oE * (oEHr * (5 / 60)) + rSj * j * 1.01 + rRj * q * 1.01;
    pillar2.targeted_reporting = q * 1.05 * rSj * 1.03 + rRj * 1.03;
    pillar2.follow_ups = m * 0.02 + j * 1.05 * rRj + q * 1.05 * rSj;
    pillar2.enhanced_presentation = mo * mCr * (rRj * 1.04) + rSj * 1.04;
    pillar2.estimate_templates = mo * mCr * (rRj * 1.05) + rSj * 1.05;
    pillar2.financing = rRj * 1.02 + rSj * 1.05;
    pillar2.smart_tags = rRj * 1.02 + rSj * 1.02 + oEHr * 0.1;
    pillar2.gls = m * 0.02 + oEHr * 0.1 * j;
    pillar2.smart_dispatch = rRj * 1.02 + rSj * 1.02;
    pillar3.marketing_tracking = m * 0.33;
    pillar3.paperless_invoicing = oEHr * (j * 0.6 + q * 0.4) * 0.167 * 20;
    pillar3.automated_payroll = oEHr * 10;
    pillar3.automated_memberships = oEHr * 10;
    pillar3.automated_reporting = oEHr * 10;
    pillar3.mobile_customer_history = j * rRj * 0.02 + q * rSj * 0.02 * 20;
    pillar3.mobile_payments = oEHr * (j * 0.6 + q * 0.4) * 0.167 * 20;
    pillar3.mobile_upcoming = j * 0.05 * rRj * 0.6 * 20;
    pillar4.customer_reporting = oEHr * 10;
    pillar4.inventory = oEHr * 10 + rtEHr * 10;
    pillar4.real_time_notification = oEHr * 5;
    pillar4.job_costing = oEHr * 10;
    pillar4.material_tracking = oEHr * 5 + rtEHr * 5;
    pillar4.dashboard = (j * rRj * 0.05 + q * rSj * 0.05) * 20;
    pillar4.forms_gated_workflow = j * rRj * 0.6 + q * rSj * 0.4 * 0.5;

    const amazingCustomerExperience = calculatePillar(pillar1, 'pillar1');
    const increaseSales = calculatePillar(pillar2, 'pillar2');
    const lowerCostsAndSaveTime = calculatePillar(pillar3, 'pillar3');
    const accountabilityAndControl = calculatePillar(pillar4, 'pillar4');

    const monthlyRevenueGrowth =
      amazingCustomerExperience + increaseSales + lowerCostsAndSaveTime + accountabilityAndControl;
    const monthlyStSubscription = 149 * 20;
    const returnOnInvestment = monthlyRevenueGrowth - monthlyStSubscription;
    const returnOnInvestmentPercent = Math.round(monthlyRevenueGrowth / monthlyStSubscription - 1);

    setFormResult({
      amazingCustomerExperience: prepareResult(amazingCustomerExperience),
      increaseSales: prepareResult(increaseSales),
      lowerCostsAndSaveTime: prepareResult(lowerCostsAndSaveTime),
      accountabilityAndControl: prepareResult(accountabilityAndControl),
      monthlyRevenueGrowth: prepareResult(monthlyRevenueGrowth),
      monthlyStSubscription: prepareResult(monthlyStSubscription),
      returnOnInvestment: prepareResult(returnOnInvestment),
      returnOnInvestmentPercent: prepareResult(returnOnInvestmentPercent),
    });
  };

  const onChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: prepareNumber(value),
    });
  };

  const checkIsValid = () => {
    const zeroVal = Object.keys(formValues).filter(
      (item) => formValues[item].length === 0 || parseInt(formValues[item]) <= 0,
    );
    return zeroVal.length === 0 && selPackage.length > 0;
  };

  const submit = () => {
    if (checkIsValid()) {
      setResult(true);
      calculate();
      pushHistory();
      setActiveTab('2');

      if (document) {
        scrollToAnchor('#resi-roi-calc-form');
      }
    }
  };

  useEffect(() => {
    let queryObj = {};

    if (globalHistory?.location?.href) {
      const params = globalHistory.location.href.split('?');

      if (params.length > 1) {
        const queryArray = params[1].split('&');
        queryObj = queryArray.reduce((accumulator, currentValue) => {
          const obj = currentValue.split('=');
          return {
            ...accumulator,
            [obj[0]]: obj[1],
          };
        }, {});
      }
    }

    setFormValues({
      m: parseFloat(queryObj.m) || '',
      oh: parseFloat(queryObj.oh) || '',
      th: parseFloat(queryObj.th) || '',
      c: parseFloat(queryObj.c) || '',
      tRc: parseFloat(queryObj.tRc) || '',
      cCr: parseFloat(queryObj.cCr) || '',
      rRj: parseFloat(queryObj.rRj) || '',
      rSj: parseFloat(queryObj.rSj) || '',
      j: parseFloat(queryObj.j) || '',
      q: parseFloat(queryObj.q) || '',
      tL: parseFloat(queryObj.tL) || '',
      rtCr: parseFloat(queryObj.rtCr) || '',
      stCr: parseFloat(queryObj.stCr) || '',
      mo: parseFloat(queryObj.mo) || '',
      mCr: parseFloat(queryObj.mCr) || '',
      oEHr: parseFloat(queryObj.oEHr) || '',
      rtEHr: parseFloat(queryObj.rtEHr) || '',
      oE: parseFloat(queryObj.oE) || '',
    });

    setSelPackage(queryObj.stPackage || '');

    setFirstLoad(true);
  }, []);

  useEffect(() => {
    if (isFirstLoad && checkIsValid()) {
      submit();
      setFirstLoad(false);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    setValid(checkIsValid());
  }, [formValues, selPackage]);

  return (
    <FormContainer
      isEmbed={otherProps.isEmbed}
      selPackage={selPackage}
      {...otherProps}
      style={{ backgroundColor: 'white' }}
    >
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container" style={{ maxWidth: 1200 }}>
          <ROIFormContainer width={width} height={height} {...otherProps} id="resi-roi-calc-form">
            <TabContext value={activeTab}>
              <TabList onChange={(e, key) => setActiveTab(key)}>
                <Tab label="MY BUSINESS" value="1" />
                <Tab label="REVENUE GROWTH" value="2" />
              </TabList>
              <TabPanel value="1">
                <div className="resi-roi-form--package-wrapper">
                  <p className="resi-roi-form--package-title">Choose your ServiceTitan package</p>
                  <div className="resi-roi-form--package-container">
                    <ROIPackageContainer
                      active={selPackage === 'starter'}
                      data-cost="199"
                      data-package="starter"
                      onClick={() => setSelPackage('starter')}
                    >
                      <p>Starter</p>
                      Monthly $199 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                    <ROIPackageContainer
                      active={selPackage === 'essentials'}
                      data-cost="249"
                      data-package="essentials"
                      onClick={() => setSelPackage('essentials')}
                    >
                      <p>Essentials</p>
                      Monthly $249 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                    <ROIPackageContainer
                      active={selPackage === 'works'}
                      data-cost="299"
                      data-package="works"
                      onClick={() => setSelPackage('works')}
                    >
                      <p>The Works</p>
                      Monthly $299 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                  </div>
                </div>

                <div
                  className="resi-roi-form--form"
                  onFinish={submit}
                  data-position="resi roi form"
                >
                  <p className="resi-roi-form--form-title">
                    By entering the metrics below, we can estimate what feature set would be most
                    beneficial for your business.
                  </p>

                  {ResiROIFormContent.map((formItem, index) => (
                    <div className="resi-roi-form--line" key={index}>
                      <div className="resi-roi-form--line-label">
                        <div className="resi-roi-form--line-icon">
                          <img src={icons[formItem.labelIcon]} alt={formItem.labelText} />
                        </div>
                        <div className="resi-roi-form--line-text">{formItem.labelText}</div>
                      </div>
                      <div className="resi-roi-form--line-input">
                        <div className="resi-roi-form--line-input-placeholder">
                          {formItem.inputPrefix}
                        </div>
                        <TextField
                          id={formItem.inputId}
                          className="input-text"
                          type="number"
                          placeholder={formItem.inputPlaceholder}
                          handleChange={onChange}
                          values={formValues}
                          showOnlyFormError
                        />
                      </div>
                    </div>
                  ))}

                  <div className="resi-roi-form--line">
                    <div className="resi-roi-form--line-text" />
                    <button
                      className={`resi-roi-form--result-button ${!isValid && 'disabled'}`}
                      {...(!isValid && { disabled: 'disabled' })}
                      onClick={submit}
                    >
                      SEE RESULTS
                    </button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2" disabled={!isResult}>
                <div className="resi-roi-form--result-wrapper">
                  <div className="resi-roi-form--result-title">
                    The following results are based on{' '}
                    <span>
                      {selPackage !== 'works' ? 'the' : ''}{' '}
                      <strong>
                        <span className="capitalize">
                          {selPackage === 'works' ? 'the' : ''} {selPackage}
                        </span>{' '}
                        package
                      </strong>
                    </span>
                  </div>

                  {ResiROIResultContent.map((resultItem, index) => (
                    <div className="resi-roi-form--result-item" key={index}>
                      <div className="resi-roi-form--result-item-row check">
                        <div className="resi-roi-form--result-item-title">{resultItem.title}</div>
                        <div className="resi-roi-form--result-item-text">
                          <p className="big">{resultItem.subhead}</p>
                          {resultItem.description}
                        </div>
                        <div className="resi-roi-form--result-item-result">
                          <div className="resi-roi-form--result-item-result-total improve-cash-flow-and-margins">
                            {`$${formResult[resultItem.resultId]}`}
                          </div>
                          <div className="resi-roi-form--result-item-result-text">
                            Revenue growth per month
                          </div>
                        </div>
                      </div>

                      <Accordion
                        onChange={(e, expanded) => {
                          setActiveKey({
                            ...activeKey,
                            [`pillar${index + 1}`]: expanded,
                          });
                        }}
                      >
                        <AccordionSummary
                          id={`pillar${index + 1}`}
                          expandIcon={<STIcon type="icon-down-caret" />}
                        >
                          <span>
                            {activeKey[`pillar${index + 1}`] ? 'Hide Feature' : 'Show Feature'}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          {resultItem.features.map((featureItem, index1) => (
                            <div
                              className={`resi-roi-form--result-item-row sub-item ${
                                (!featureItem.except || !featureItem.except.includes(selPackage)) &&
                                'check'
                              }`}
                              key={index1}
                            >
                              <div className="resi-roi-form--result-item-space" />
                              <div className="resi-roi-form--result-item-text">
                                <p>
                                  {featureItem.title}
                                  <div className="resi-roi-form--result-item-result-check" />
                                </p>
                                {featureItem.description}
                              </div>
                              <div className="resi-roi-form--result-item-result">
                                <div className="resi-roi-form--result-item-result-check" />
                                <div className="resi-roi-form--result-item-result-not-check">
                                  <div className="resi-roi-form--result-item-result-text">
                                    Offered in:
                                    <br />
                                    Essentials and
                                    <br />
                                    The Works
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}

                  <div className="resi-roi-form--result-total">
                    <div className="resi-roi-form--result-total-row">
                      <span>Monthly Revenue Growth</span>
                      <span className="monthly-revenue-growth highlight">{`$${formResult.monthlyRevenueGrowth}`}</span>
                    </div>
                    <div className="resi-roi-form--result-total-row">
                      <span>Monthly ServiceTitan Subscription</span>
                      <span className="monthly-st-subscription">{`$${formResult.monthlyStSubscription}`}</span>
                    </div>
                    <div className="resi-roi-form--result-total-row">
                      <span>Return on Investment</span>
                      <span className="return-on-investment highlight">{`$${formResult.returnOnInvestment}`}</span>
                      <p>
                        This is{' '}
                        <span className="return-on-investment-percent">{`${formResult.returnOnInvestmentPercent}x`}</span>{' '}
                        return on investment per month
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </ROIFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default EmbedForm(ResiROIForm);
