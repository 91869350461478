import styled from 'styled-components';

export const ROIFormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 60px 0;
  background: var(--white);

  .MuiTabs-root {
    overflow: visible;

    .MuiTabs-scroller {
      border-bottom: 1px solid rgba(216, 216, 216, 0.34);

      .MuiTab-root {
        font-family: var(--font-family-body);
        font-size: var(--font-size-22);
        margin-right: 80px;
        padding-bottom: 26px;

        &.Mui-selected {
          font-weight: var(--font-weight-700);
          color: var(--darkest);
        }

        @media (max-width: 575px) {
          font-size: var(--font-size-18);
        }

        @media (max-width: 575px) {
          margin-right: 30px;
          padding-bottom: 15px;
        }
      }

      .MuiTabs-indicator {
        background-color: var(--titan-blue-3);
        height: 3px;
      }
    }
  }

  .MuiAccordion-root {
    border: none;
    box-shadow: none;
    margin: 0;
    display: flex;
    flex-direction: column-reverse;

    .MuiAccordionSummary-root {
      padding: 10px 0 0;
    }

    .MuiAccordionSummary-content {
      flex-grow: 0;
      margin: 15px 0;
      padding-right: 10px;

      span {
        font-size: var(--font-size-16);
        color: var(--grey-5);
        margin: 0;
      }
    }

    &.Mui-expanded {
      .MuiAccordionSummary-content {
        span {
          color: var(--dark);
        }
      }
    }
  }

  .resi-roi-form--package-wrapper {
    margin-top: 40px;

    .resi-roi-form--package-title {
      font-size: var(--font-size-18);
      line-height: 28px;
      font-weight: var(--font-weight-700);
      color: var(--dark);
      margin-bottom: 20px;
    }

    .resi-roi-form--package-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 -20px;
    }
  }

  .resi-roi-form--form {
    border-radius: 6px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    margin: 40px auto 0;
    padding: 18px 60px 5px;

    .resi-roi-form--form-title {
      font-size: var(--font-size-16);
      line-height: 24px;
      color: var(--grey-5);
      margin-bottom: 18px;
    }

    .input-text {
      width: 200px;
      height: 50px;

      .text-field-input {
        height: 100% !important;
        font-size: var(--font-size-16);
        text-align: right;
        border-radius: 4px;
        padding: 14px 20px;
        transition: all 0.3s ease-in-out;
      }
    }

    .resi-roi-form--line {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 45px 0;
      border-top: 1px solid #d8d8d8;

      .resi-roi-form--line-label {
        flex: auto;
        display: flex;
        align-items: center;
      }

      .resi-roi-form--line-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 20px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .resi-roi-form--line-text {
        flex: auto;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-700);
        color: var(--dark);
        min-width: 0;
        margin-right: 20px;
      }

      .resi-roi-form--line-input {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .resi-roi-form--line-input-placeholder {
        font-size: var(--font-size-18);
        color: var(--dark);
        margin-right: 9px;
        min-width: 13px;
      }

      .resi-roi-form--result-button {
        width: 200px;
        height: 50px;
        color: var(--white);
        font-size: var(--font-size-16);
        line-height: 50px;
        font-weight: var(--font-weight-700);
        text-align: center;
        background-color: var(--titan-blue-3);
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      .resi-roi-form--result-button:hover {
        background-color: var(--titan-blue-2);
      }

      .resi-roi-form--result-button:active {
        background-color: var(--titan-blue-4);
      }

      .resi-roi-form--result-button.disabled {
        background-color: var(--grey-1);
        cursor: default;
      }
    }
  }

  .resi-roi-form--result-wrapper {
    margin-top: 40px;

    .resi-roi-form--result-title {
      font-size: var(--font-size-18);
      line-height: 28px;
      color: var(--grey-7);
      margin-bottom: 20px;

      strong {
        font-weight: var(--font-weight-700);
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .resi-roi-form--result-item {
      padding: 40px 60px 0;
      border-radius: 6px;
      margin-bottom: 40px;
      overflow: hidden;
      position: relative;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

      .resi-roi-form--result-item-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 20px 0 23px;
        border-bottom: 1px solid #e9eff6;

        .resi-roi-form--result-item-title {
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-700);
          letter-spacing: 1px;
          padding: 14px 25px;
          color: var(--grey-5);
          background-color: var(--grey-1);
          border-radius: 25px;
          margin-right: 60px;
          white-space: nowrap;
        }

        .resi-roi-form--result-item-space {
          min-width: 124px;
          margin-right: 60px;
        }

        .resi-roi-form--result-item-text {
          flex: auto;
          font-size: var(--font-size-13);
          line-height: 24px;
          color: var(--grey-5);

          p {
            font-size: var(--font-size-16);
            line-height: var(--line-height-120);
            font-weight: var(--font-weight-700);
            margin-bottom: 8px;
            color: var(--grey-5);

            &.big {
              font-size: var(--font-size-22);
            }
          }
        }

        .resi-roi-form--result-item-result {
          min-width: 200px;

          .resi-roi-form--result-item-result-total {
            font-size: var(--font-size-22);
            font-weight: var(--font-weight-700);
            color: var(--green);
            text-align: right;
            margin-bottom: 6px;
          }

          .resi-roi-form--result-item-result-text {
            color: var(--grey-5);
            font-size: var(--font-size-13);
            font-weight: var(--font-weight-600);
            line-height: 24px;
            text-align: right;
          }
        }

        .resi-roi-form--result-item-result-check {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--green);
          position: absolute;
          right: 0;
          top: 20px;

          &::before {
            content: '';
            display: block;
            width: 8px;
            height: 4px;
            border: 1px solid var(--white);
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
            position: absolute;
            left: 6px;
            top: 6px;
          }
        }

        .resi-roi-form--result-item-result-check {
          display: none;
        }

        &.check {
          .resi-roi-form--result-item-text {
            color: var(--dark);

            p {
              color: var(--dark);
            }
          }

          .resi-roi-form--result-item-result-check {
            display: block;
          }

          .resi-roi-form--result-item-result-not-check {
            display: none;
          }
        }

        .resi-roi-form--result-item.sub-item {
          .resi-roi-form--result-item-result-check {
            display: none;
          }
        }
      }

      .resi-roi-form--result-item-read-more {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        padding: 15px 0 16px;
        background: var(--white);
        position: absolute;
        bottom: 0;
        left: 0;

        span {
          font-size: var(--font-size-16);
          line-height: 28px;
          color: var(--grey-5);
        }

        svg {
          fill: var(--grey-5);
          margin-left: 10px;
        }

        .hide {
          display: block;
          color: var(--dark);
        }
      }
    }

    .resi-roi-form--result-total {
      padding: 0 60px;
      background-color: var(--dark);
      border-radius: 6px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

      .resi-roi-form--result-total-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 38px 0;
        border-bottom: 1px solid rgba(174, 183, 189, 0.15);

        span {
          color: var(--grey-1);
          font-size: var(--font-size-18);
          font-weight: var(--font-weight-700);

          &.highlight {
            color: var(--green);
          }
        }

        p {
          width: 100%;
          font-size: var(--font-size-18);
          color: var(--grey-6);
          margin-top: 17px;

          span {
            color: var(--green);
          }
        }

        .return-on-investment {
          font-size: var(--font-size-22);
        }
      }
    }
  }

  @media (max-width: 991px) {
    .resi-roi-form--result-wrapper {
      .resi-roi-form--result-item {
        padding-left: 30px;
        padding-right: 30px;

        .resi-roi-form--result-item-row {
          .resi-roi-form--result-item-title {
            margin-right: 20px;
          }
          .resi-roi-form--result-item-space {
            margin-right: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0;

    .resi-roi-form--package-wrapper {
      .resi-roi-form--package-container {
        flex-direction: column;
        margin: -10px 0;
      }
    }

    .resi-roi-form--form {
      padding: 20px;

      .resi-roi-form--form-item {
        margin-bottom: 24px;
      }

      .resi-roi-form--submit-button {
        width: 100%;
      }
    }

    .resi-roi-form--result-wrapper {
      .resi-roi-form--result-item {
        padding: 20px 0 67px;

        .resi-roi-form--result-item-row {
          flex-direction: column;
          align-items: flex-start;
          padding: 10px 20px 23px;

          .resi-roi-form--result-item-space {
            display: none;
          }

          .resi-roi-form--result-item-text {
            margin: 30px 0 20px 15px;
          }

          .resi-roi-form--result-item-result {
            width: 100%;
          }

          &.sub-item {
            flex-direction: row;
            padding: 22px 0;

            .resi-roi-form--result-item-text {
              margin: 0;
              flex: auto;
              padding: 0 20px;
            }

            .resi-roi-form--result-item-result {
              display: none;
            }

            .resi-roi-form--result-item-result-check {
              position: relative;
              left: 0;
              top: 4px;
              right: 0;
              margin-left: 10px;
            }

            &.check .resi-roi-form--result-item-result-check {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding: 20px 0;

    .resi-roi-form--form {
      .resi-roi-form--form-title {
        font-size: var(--font-size-13);
        text-align: center;
      }

      .resi-roi-form--line {
        flex-wrap: wrap;
        padding: 20px 0;

        .resi-roi-form--line-input {
          width: 100%;
          margin-top: 20px;
          justify-content: center;
        }
      }
    }

    .resi-roi-form--result-wrapper {
      .resi-roi-form--result-item {
        .resi-roi-form--result-item-row.sub-item {
          padding: 22px 0;

          .resi-roi-form--result-item-text {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const ROIPackageContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px;
  font-size: var(--font-size-16);
  line-height: 28px;
  color: var(--grey-5);
  border: 2px solid var(--grey-1);
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  margin: 0 20px;

  p {
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-700);
    line-height: normal;
    color: var(--dark);
    margin-bottom: 3px;
    transition: all 0.3s ease-in-out;
  }

  .checkbox {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 4px;
    background-color: transparent;
    border: 4px solid var(--grey-1);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    border-color: var(--titan-blue-3);
  }

  ${(props) =>
    props.active &&
    `
    border-color: var(--titan-blue-3);
    p {
      color: var(--titan-blue-3);
    }

    .checkbox {
      background-color: var(--titan-blue-3);
      border-color: var(--titan-blue-3);

      &::after {
        content: '';
        display: block;
        width: 4px;
        height: 11px;
        position: absolute;
        top: 9px;
        left: 5px;
        background: var(--white);
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
        transform: rotate(-45deg);
      }

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 17px;
        position: absolute;
        top: 4px;
        right: 7px;
        background: var(--white);
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        transform: rotate(45deg);
      }
    }
  `};

  @media (max-width: 767px) {
    margin: 10px 0;
  }
`;
