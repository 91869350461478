/* eslint-disable import/prefer-default-export */

export const ResiROIFormContent = [
  {
    labelIcon: 'money',
    labelText: 'Marketing Budget per month',
    inputPrefix: '$',
    inputId: 'm',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'money',
    labelText: 'Avg Office Hourly Rate',
    inputPrefix: '$',
    inputId: 'oh',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'money',
    labelText: 'Avg Tech Hourly Rate',
    inputPrefix: '$',
    inputId: 'th',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'person',
    labelText: 'Phone calls per Day',
    inputId: 'c',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'person',
    labelText: 'Time dedicated per customer',
    inputId: 'tRc',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'eval',
    labelText: 'Call Conversion Rate',
    inputPrefix: '%',
    inputId: 'cCr',
    inputPlaceholder: 'percentage',
  },
  {
    labelIcon: 'money',
    labelText: 'Avg repair ticket',
    inputPrefix: '$',
    inputId: 'rRj',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'money',
    labelText: 'Avg install ticket',
    inputPrefix: '$',
    inputId: 'rSj',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Avg jobs per day per repair tech',
    inputId: 'j',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Avg quotes/Install per day per sales tech/Install crew',
    inputId: 'q',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Tech Leads per day',
    inputId: 'tL',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'eval',
    labelText: 'Tech Close Rate',
    inputPrefix: '%',
    inputId: 'rtCr',
    inputPlaceholder: 'percentage',
  },
  {
    labelIcon: 'eval',
    labelText: 'Sales Close Rate',
    inputPrefix: '%',
    inputId: 'stCr',
    inputPlaceholder: 'percentage',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Memberships Offered',
    inputId: 'mo',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'eval',
    labelText: 'Memberships Converted',
    inputPrefix: '%',
    inputId: 'mCr',
    inputPlaceholder: 'percentage',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Office Employees (hourly rate)',
    inputId: 'oEHr',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'eval',
    labelText: 'Repair Techs (hourly rate)',
    inputPrefix: '$',
    inputId: 'rtEHr',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Office Employees',
    inputId: 'oE',
    inputPlaceholder: '#',
  },
];

export const ResiROIResultContent = [
  {
    title: 'PILLAR 1',
    subhead: 'Amazing Customer Experience',
    description: 'Amazing Customer Experience',
    resultId: 'amazingCustomerExperience',
    features: [
      {
        title: `Invoice Quickly`,
        description: `Get invoices out faster with material lists, custom mark up tables and custom labor rates the technician can add onsite.`,
      },
      {
        title: `Speed to information`,
        description: `Get technicians in and out of jobs faster with proper information in the field, proper contact information, customer history, arrival information for each customer and location.`,
      },
    ],
  },
  {
    title: 'PILLAR 2',
    subhead: 'Increase Sales',
    description: 'Increase Sales',
    resultId: 'increaseSales',
    features: [
      {
        title: `Invoice Quickly`,
        description: `Get invoices out faster with material lists, custom mark up tables and custom labor rates the technician can add onsite.`,
      },
      {
        title: `Speed to information`,
        description: `Get technicians in and out of jobs faster with proper information in the field, proper contact information, customer history, arrival information for each customer and location.`,
      },
    ],
  },
  {
    title: 'PILLAR 3',
    subhead: 'Lower costs and save time',
    description: 'Lower costs and save time',
    resultId: 'lowerCostsAndSaveTime',
    features: [
      {
        title: `Invoice Quickly`,
        description: `Get invoices out faster with material lists, custom mark up tables and custom labor rates the technician can add onsite.`,
      },
      {
        title: `Speed to information`,
        description: `Get technicians in and out of jobs faster with proper information in the field, proper contact information, customer history, arrival information for each customer and location.`,
      },
    ],
  },
  {
    title: 'PILLAR 4',
    subhead: 'Accountability and control',
    description: 'Accountability and control',
    resultId: 'accountabilityAndControl',
    features: [
      {
        title: `Invoice Quickly`,
        description: `Get invoices out faster with material lists, custom mark up tables and custom labor rates the technician can add onsite.`,
      },
      {
        title: `Speed to information`,
        description: `Get technicians in and out of jobs faster with proper information in the field, proper contact information, customer history, arrival information for each customer and location.`,
      },
    ],
  },
];
